import styled from '@emotion/styled'
import Headings from '@/components/Headings'
import mediaqueries from '@/styles/media'

const InnerHTML = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Headings.commonStyles}
  }

  h1 {
    font-size: 22px;
    margin: 20px 0;
  }

  h2 {
    font-size: 20px;
    margin: 20px 0;
  }

  h3 {
    font-size: 18px;
    line-height: 1.45;
    margin: 0 0 10px;

    ${mediaqueries.tablet`
    font-size: 22px;
  `};

    ${mediaqueries.phablet`
    font-size: 20px;
  `};
  }

  p {
    line-height: 1.756;
    font-size: 16px;
    color: ${(p) => p.theme.colors.articleText};
    font-family: ${(p) => p.theme.fonts.body};
    transition: ${(p) => p.theme.colorModeTransition};
    margin: 0 auto;
    margin-bottom: 15px;
    width: 100%;

    b {
      font-weight: 800;
    }
  }

  img {
    display: block;
    max-width: 100%;
    width: 100%;
    margin: 1rem 0;
    padding: 0;
  }

  ul {
    list-style: none;
    font-family: ${(p) => p.theme.fonts.body};
    counter-reset: list;
    color: ${(p) => p.theme.colors.articleText};
    position: relative;
    padding: 15px 0 30px 30px;
    transition: ${(p) => p.theme.colorModeTransition};
    margin: 0 auto;
    font-size: 16px;
    z-index: 1;

    width: 100%;

    ${mediaqueries.tablet`
    max-width: 486px;
    padding-left: 0px;
  `};

    ${mediaqueries.phablet`
    padding-left: 20px;
  `};

    li {
      position: relative;
      padding-bottom: 8px;
      font-size: 16px;

      &:before {
        width: 3rem;
        display: inline-block;
        position: absolute;
        color: ${(p) => p.theme.colors.articleText};
      }

      &:before {
        content: '';
        position: absolute;
        left: -20px;
        top: 9px;
        height: 6px;
        width: 6px;
        background: ${(p) => p.theme.colors.black};

        ${mediaqueries.tablet`
        left: 0;
      `};
      }

      ${mediaqueries.tablet`
      padding-left: 30px;
    `};

      ${mediaqueries.phablet`
      padding-left: 20px;
      padding-right: 20px;
    `};

      p {
        ${mediaqueries.tablet`
        padding: 0;
      `};
      }
    }
  }

  a {
    transition: ${(p) => p.theme.colorModeTransition};
    color: ${(p) => p.theme.colors.primary};
    border-bottom: 1px solid ${(p) => p.theme.colors.primary};

    &:visited {
      color: ${(p) => p.theme.colors.primary};
      opacity: 0.85;
    }

    &:hover,
    &:focus {
      color: ${(p) => p.theme.colors.stickyNavBackground};
      border-bottom-color: ${(p) => p.theme.colors.horizontalRule};
    }
  }

  blockquote {
    position: relative;
    transition: ${(p) => p.theme.colorModeTransition};
    margin: 50px auto;
    color: ${(p) => p.theme.colors.primary};
    font-family: ${(p) => p.theme.fonts.body};
    font-style: italic;
    font-size: 25px;
    font-weight: 300;
    line-height: 1.32;
    width: 100%;
    max-width: 700px;
    padding: 0;

    ${mediaqueries.phablet`
  font-size: 36px;
  padding: 0 20px 0 40px;
  `};

    &:before {
      content: '“';
      font-family: serif;
      position: absolute;
      right: 100%;
      font-size: 80px;
      line-height: 0px;
      top: 20px;
      color: rgb(225, 225, 225);

      ${mediaqueries.tablet`
          left: -10px;
      `};
    }

    footer {
      font-family: ${(p) => p.theme.fonts.body};
      font-size: 0.6em;
      text-align: right;
      color: ${(p) => p.theme.colors.articleText};
      margin-top: 20px;

      &:before {
        content: '- ';
      }

      ${mediaqueries.tablet`
         font-size: 0.4em;
      `};
    }

    cite {
      float: right;
      font-size: 15px;
    }

    ${mediaqueries.tablet`
    margin: 30px auto 35px;
  `};
  }
`
export default InnerHTML

import styled from '@emotion/styled'
import mediaqueries from '@/styles/media'

const OrderedList = styled.ol`
  list-style: none;
  font-family: ${p => p.theme.fonts.body};
  counter-reset: list;
  color: inherit;
  position: relative;
  padding: 15px 0 30px 30px;
  margin: 0 auto;
  transition: ${p => p.theme.colorModeTransition};
  font-size: 18px;
  font-weight: 600;
  z-index: 1;

  width: 100%;

  ${mediaqueries.phablet`
    padding-left: 20px;
  `};

  ${p =>
    p.fullWidth &&
    `
    max-width: 100%;
  `}

  p {
    padding-bottom: 20px;
    ${mediaqueries.tablet`
      padding: 0;
    `};
  }

  li {
    color: ${p => p.theme.colors.black};
    position: relative;
    padding-bottom: 15px;

    ${mediaqueries.tablet`
      padding-left: 30px;
    `};

    ${mediaqueries.phablet`
      padding-left: 30px;
    `};
  }

  li > :not(ol, ul) {
    display: inline;
  }

  li::before {
    width: 3rem;
    display: inline-block;
    position: absolute;
    color: inherit;
  }

  li::before {
    counter-increment: list;
    content: counter(list) '.';
    font-weight: 600;
    position: absolute;
    left: -3rem;
    top: -0.2rem;
    font-size: 2rem;

    ${mediaqueries.tablet`
      left: 0;
    `};
  }
`

export default OrderedList

import React, { useEffect } from 'react'
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript'

import Block from '@/components/Block'
import Lists from '@/components/Lists'

import 'highlight.js/styles/github.css'

const CodeSnippet = ({ code }) => {
  useEffect(() => {
    hljs.registerLanguage('javascript', javascript)
    document.querySelectorAll('pre code').forEach((block) => {
      hljs.highlightBlock(block)
    })
  })
  return (
    <pre>
      <code>{code}</code>
    </pre>
  )
}

export const Serializer = {
  container: ({ children }) => <React.Fragment>{children}</React.Fragment>,
  types: {
    block: Block,
  },
  list: (props) => <Lists.ul fullWidth>{props.children}</Lists.ul>,
  marks: {
    tick: (props) => <span className="tick">{props.children}</span>,
    code: (props) => <CodeSnippet code={props.children} />,
  },
}

import styled from '@emotion/styled'
import { buildImageObj } from '@/utils/helpers'
import imageUrlFor from '@/utils/image-url'

const Banner = styled.section`
  width: 100%;
  position: relative;
  height: 500px;
  background: ${(p) =>
    `url(${imageUrlFor(buildImageObj(p.backgroundImage))}) no-repeat center center`};
  background-size: cover;
  display: flex;
  align-items: center;
  text-align: center;

  &:before {
    mix-blend-mode: darken;
    // background-position-y: -180px !important;
  }
`
export default Banner

import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import styled from '@emotion/styled'

import Paragraph from '@/components/Paragraph'
import Headings from '@/components/Headings'
import InnerHTML from '@/components/InnerHTML'

const Block = (props) => {
  const { style = 'normal', _key } = props.node

  if (/^h\d/.test(style)) {
    // const Text = style
    return <Headings.PageHead id={_key}>{props.children}</Headings.PageHead>
  }

  if (style === 'normal') {
    return (
      <Text id={_key} fullWidth>
        {props.children}
      </Text>
    )
  }

  if (style === 'blockquote') {
    return (
      <InnerHTML>
        <blockquote>{props.children}</blockquote>
      </InnerHTML>
    )
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
  // return <InnerHTML>{props.children}</InnerHTML>
}

export default Block

const StyledParagraph = styled(Paragraph)`
  line-height: 1.9;
  font-size: 16px;
  font-weight: 400;
`

const Text = styled(StyledParagraph)`
  margin-bottom: 5rem;
`
